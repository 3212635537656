import script from "./ConfirmAccount.vue?vue&type=script&setup=true&lang=ts"
export * from "./ConfirmAccount.vue?vue&type=script&setup=true&lang=ts"

import "./ConfirmAccount.vue?vue&type=style&index=0&id=169983f6&lang=scss"

const __exports__ = script;

export default __exports__
import QImg from 'quasar/src/components/img/QImg.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerBars from 'quasar/src/components/spinner/QSpinnerBars.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QForm,QInput,QBtn,QSpinnerBars});
